<template>
  <b-table
    :data="templateGroups"
    :scrollable="true"
    detailed
    detail-key="name"
    :paginated="paginated"
    backend-pagination
    :current-page="page"
    :per-page="perPage"
    :total="totalResults"
    :loading="loading"
    :row-class="(row, index) => 'center-cell-vertical'"
    @page-change="onPageChange"
  >
    <b-table-column
      field="name"
      :label="$t('policies.folder.name.label')"
      searchable
    >
      <template #searchable="props">
        <b-input
          v-model="filters[props.column.field]"
          :placeholder="$t('search')"
          icon="magnify"
        />
      </template>

      <template #default="props">
        {{ props.row.name }}
      </template>
    </b-table-column>

    <b-table-column
      field="merchant"
      :label="$t('policies.merchant.name.label')"
      v-slot="props"
    >
      {{ props.row.merchant.external_name }}
    </b-table-column>

    <b-table-column
      field="timeout_signature_days"
      :label="$t('policies.folder.timeout.label')"
      v-slot="props"
    >
      {{ props.row.timeout_signature_days }}
    </b-table-column>

    <b-table-column
      field="pec_address"
      :label="$t('policies.folder.pec.label')"
      v-slot="props"
    >
      {{ props.row.pec_address }}
    </b-table-column>

    <b-table-column
      field="type"
      :label="$t('policies.folder.type.label')"
      v-slot="props"
    >
      {{ $t(`policies.folder.type.${props.row.type}`) }}
    </b-table-column>

    <b-table-column
      field="is_public"
      :label="$t('policies.folder.visibility')"
      centered
      v-slot="props"
    >
      <b-icon :icon="props.row.is_public ? 'eye' : 'eye-off'"></b-icon>
    </b-table-column>

    <slot name="group-buttons"></slot>

    <!-- <b-table-column v-slot="{ row }">
      <div class="buttons">
        <b-button
          type="is-primary"
          icon-right="lead-pencil"
          @click.stop="modifyFolder(row)"
        ></b-button>
        <b-button
          type="is-danger"
          icon-right="delete"
          @click.stop="deleteFolder(row)"
        ></b-button>
      </div>
    </b-table-column> -->

    <template #detail="{ row }">
      <b-table
        :data="row.templates"
        :striped="false"
        :narrowed="false"
        :hoverable="true"
        :mobile-cards="true"
        :row-class="(row, index) => 'center-cell-vertical is-clickable'"
        @click="onTemplateRowClick"
      >
        <!-- <b-table-column field="id" label="ID" width="40" numeric v-slot="props">
          {{ props.row.id }}
        </b-table-column> -->

        <b-table-column
          field="type"
          :label="$t('policies.template.type.label')"
          v-slot="props"
        >
          {{ $t(`policies.${props.row.type}.name`) }}
        </b-table-column>

        <b-table-column
          field="name"
          :label="$t('policies.template.name.label')"
          v-slot="props"
        >
          {{ props.row.name }}
        </b-table-column>

        <b-table-column
          field="date"
          :label="$t('policies.template.date.label')"
          centered
          v-slot="props"
        >
          {{ new Date(props.row.date).toLocaleDateString() }}
        </b-table-column>

        <b-table-column
          field="signers_nr"
          :label="$t('policies.template.signers_nr.label')"
          centered
          v-slot="props"
        >
          <span class="tag is-medium">
            {{ props.row.signers_nr }}
          </span>
        </b-table-column>

        <template #empty>
          <div class="has-text-centered">No templates</div>
        </template>
      </b-table>
    </template>

    <template #footer>
      <slot name="footer"></slot>
    </template>
    <template #bottom-left>
      <slot name="bottom-left"></slot>
    </template>
    <template #empty>
      <div class="has-text-centered">
        {{ $t("policies.folder.search.not_found") }}
      </div>
    </template>
  </b-table>
</template>

<script>
import _ from "lodash";

export default {
  props: {
    templateGroups: {
      type: Array,
      default: () => [],
    },
    paginated: {
      default: false,
    },
    page: {
      default: 1,
    },
    perPage: {
      default: 10,
    },
    totalResults: {
      default: 0,
    },
    loading: {
      default: false,
    },
  },
  data() {
    return {
      filters: {
        name: "",
      },
    };
  },
  computed: {
    isPaginated() {
      return this.paginated && this.totalResults > this.templateGroups.length
        ? true
        : false;
    },
  },
  watch: {
    filters: {
      deep: true,
      handler: function () {
        this.onFilter();
      },
    },
  },
  methods: {
    deleteFolder(folder) {
      this.$emit("deleteFolder", folder);
    },
    modifyFolder(folder) {
      this.$emit("modifyFolder", folder);
    },
    onTemplateRowClick(row) {
      this.$emit("templateClick", row);
    },
    onFilter: _.debounce(function () {
      this.$emit("filter", this.filters);
    }, 500),
    onPageChange(pageNum) {
      this.$emit("page-change", { filters: this.filters, page: pageNum });
    },
  },
};
</script>

<style></style>
