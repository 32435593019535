<template>
  <div>
    <b-loading :active="isLoading" :is-full-page="true"></b-loading>

    <b-modal v-model="showGroupImport">
      <div class="box">
        <folder-table
          :templateGroups="groupList"
          :paginated="true"
          :page="groupPage"
          :perPage="groupPerPage"
          :totalResults="groupTotal"
          :loading="loadingGroups"
          @filter="getTemplateGroups($event)"
          @page-change="
            (evt) => {
              groupPage = evt.page;
              getTemplateGroups(evt.filters);
            }
          "
        >
          <template #group-buttons>
            <b-table-column v-slot="{ row }">
              <div class="buttons">
                <b-button
                  type="is-primary"
                  icon-left="download"
                  @click.stop="importGroup(row)"
                  >{{ $t("buttons.import") }}</b-button
                >
              </div>
            </b-table-column>
          </template>
        </folder-table>
      </div>
    </b-modal>

    <ValidationObserver
      ref="observer"
      tag="section"
      class="section has-background-eurotext-light block"
    >
      <div class="container block">
        <!-- Folder type radio buttons -->
        <b-field>
          <template #label
            ><span class="has-text-light">{{
              $t("policies.folder.type.label")
            }}</span></template
          >
          <b-radio
            v-for="ft in folderTypes"
            :key="ft.id"
            v-model="selectedFolderType"
            :native-value="ft"
            type="is-white"
          >
            <span class="has-text-light">{{
              $t(`policies.folder.type.${ft.id}`)
            }}</span>
          </b-radio>
        </b-field>

        <!-- Select folder button -->
        <div class="container block">
          <b-button @click="getTemplateGroups" type="is-primary">
            {{ $t(`policies.folder.button.select_folder`) }}
          </b-button>
        </div>

        <b-field v-if="!isPrivacy" class="v-center-fields" grouped>
          <!-- External ID text box -->
          <b-input-with-validation
            vid="extId"
            :name="$t('policies.extId.label')"
            v-model="extId"
            :input-attrs="{
              placeholder: $t('policies.extId.placeholder'),
            }"
            :fieldAttrs="{ expanded: false }"
            rules="required"
          >
            <template #label
              ><span class="has-text-light">{{
                $t("policies.extId.label")
              }}</span></template
            >
          </b-input-with-validation>

          <!-- Appendix ID text box (shown only if Folder type is Appendix) -->
          <b-input-with-validation
            v-if="isAppendix"
            vid="appendixId"
            :name="$t('policies.appendixAttr.label')"
            v-model="appendixId"
            :input-attrs="{
              placeholder: $t('policies.appendixAttr.placeholder'),
            }"
            :fieldAttrs="{ expanded: false }"
            rules="required"
          >
            <template #label
              ><span class="has-text-light">{{
                $t("policies.appendixAttr.label")
              }}</span></template
            >
          </b-input-with-validation>
        </b-field>

        <b-field grouped>
          <!-- <b-input-with-validation
            vid="pec"
            :name="$t('policies.folder.pec.label')"
            v-model="pecAddr"
            :input-attrs="{
              placeholder: $t('policies.folder.pec.placeholder'),
            }"
            :fieldAttrs="{ expanded: false }"
            style="min-width: 15rem"
            rules="email"
          >
            <template #label
              ><span class="has-text-light">{{
                $t("policies.folder.pec.label")
              }}</span></template
            >
          </b-input-with-validation> -->

          <!-- Sign validity days text box -->
          <b-input-with-validation
            vid="timeout"
            :name="$t('policies.folder.timeout.label')"
            v-model="timeoutDays"
            :input-attrs="{
              placeholder: $t('policies.folder.timeout.placeholder'),
            }"
            :fieldAttrs="{ expanded: false }"
            rules="numeric|min_value:1"
          >
            <template #label
              ><span class="has-text-light">{{
                $t("policies.folder.timeout.label")
              }}</span></template
            >
          </b-input-with-validation>

          <!-- Pay date DatePicker (shown if at least a template pay_count > 0) -->
          <ValidationProvider
            vid="payday"
            :name="$t('policies.template.payday.label')"
            :rules="`${!paymentOptions.required ? 'required' : ''}`"
            v-slot="{ errors }"
            v-if="showPaydayInput"
            slim
          >
            <b-field :type="{ 'is-danger': errors[0] }" :message="errors">
              <template #label>
                <span class="has-text-light">{{
                  $t("policies.template.payday.label")
                }}</span>
              </template>
              <custom-b-date-picker
                v-model="paydayDate"
                icon="calendar-today"
                :placeholder="$t('policies.template.payday.placeholder')"
                :locale="$i18n.locale"
                :dateFormat="$store.getters.getDatePattern"
              ></custom-b-date-picker>
            </b-field>
          </ValidationProvider>
        </b-field>
      </div>

      <!-- <div class="container block">
        <b-button @click="getTemplateGroups" type="is-primary">
          {{ $t(`policies.folder.button.select_folder`) }}
        </b-button>
      </div> -->

      <!-- Payment checkbox (and textbox if checkbox is checked, showed if merchant isPay) -->
      <div class="container block" v-if="isPayEnabled">
        <PaymentSelection
          :defaultIsRequired="paymentOptions.required"
          :defaultCurrency="paymentOptions.currency"
          :defaultAmount="paymentOptions.amount"
          :paymentMerchants="remoteMerchants"
          :defaultPaymentMerchant="defaultRemoteMerchant"
          @change="onPaymentSelectionChange"
          ref="paymentSelection"
        ></PaymentSelection>
      </div>

      <!--  -->
      <div class="container block">
        <div class="columns is-multiline">
          <div
            v-for="tem in templateList"
            :key="`${tem.type}_${tem.id}`"
            class="column"
          >
            <template-data
              :signerRoles="signerRoles"
              :template="tem"
              :merchantUniqueName="merchantUniqueName"
              @user="selectUser($event, tem)"
              @file="selectFile($event, tem)"
              @preview="openPreview(tem)"
            ></template-data>
          </div>
        </div>
      </div>

      <div class="container block" v-show="availableRoles.length > 0">
        <div class="columns is-centered">
          <div class="column is-half">
            <table class="table is-bordered is-fullwidth">
              <thead>
                <tr>
                  <th
                    v-for="role of availableRoles"
                    :key="`${role.id}_${role.name}`"
                    class="has-text-centered"
                  >
                    {{ role.description }}
                  </th>
                </tr>
                <tr>
                  <td
                    v-for="role of availableRoles"
                    :key="`${role.id}_${role.name}`"
                  >
                    <sig-user-selection
                      :companyName="companyName"
                      :role="role"
                      :merchantUniqueName="merchantUniqueName"
                      @user="onUserSelect($event, role)"
                    ></sig-user-selection>
                  </td>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
    </ValidationObserver>

    <div class="block level">
      <div class="level-item">
        <b-button type="is-primary" size="is-medium" @click="upload">{{
          $t(`buttons.upload`)
        }}</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import BInputWithValidation from "@/components/inputs/BInputWithValidation.vue";
import { folderTypes, isAppendix, isPrivacy } from "@/helpers/constants";
import { getBase64FromFile, hexSHA256FromFile } from "../../helpers";
import { commonMixin, templateMixin } from "@/mixins";
import { policiesService, responseSchemas } from "../../services";
import FolderTable from "../../components/policies/FolderTable.vue";
import TemplateData from "../../components/policies/upload/TemplateData";
import SigUserSelection from "../../components/policies/upload/SigUserSelection.vue";
import PaymentSelection from "../../components/policies/upload/PaymentSelection";
import { KAgent, KCustomer, KSubAgent } from "../../helpers/constants";
import CustomBDatePicker from "../../components/inputs/CustomBDatePicker.vue";
import { toStringISODate } from "../../helpers/date";
const POLICY_PAYMENT_REQUIRED_STORAGE_KEY = "policy_payment_required";
export default {
  mixins: [commonMixin, templateMixin],
  components: {
    ValidationObserver,
    ValidationProvider,
    BInputWithValidation,
    FolderTable,
    TemplateData,
    SigUserSelection,
    CustomBDatePicker,
    PaymentSelection,
  },
  props: {},
  data() {
    return {
      pecAddr: null,
      extId: "",
      appendixId: null,
      timeoutDays: null,
      showPaydayInput: false,
      paydayDate: null,
      showGroupImport: false,
      loadingGroups: false,
      // loadingMerchants: false,
      remoteMerchants: [],
      defaultRemoteMerchant: null,
      uploading: false,
      loadingPreview: false,
      folderTypes: folderTypes,
      selectedFolderType: folderTypes[0],
      groupPage: 1,
      groupPerPage: 50,
      groupTotal: 0,
      groupList: [],
      selectedGroup: null,
      templateList: [],
      signerRoles: [],
      selectedUsers: {},
      isPayEnabled: false,
      paymentOptions: {
        required: false,
        amount: "",
        currency: "EUR",
      },
    };
  },
  computed: {
    isLoading() {
      return (
        this.loadingGroups ||
        this.loadingMerchants ||
        this.uploading ||
        this.loadingPreview
      );
    },
    isAppendix() {
      return isAppendix(this.selectedFolderType);
    },
    isPrivacy() {
      return isPrivacy(this.selectedFolderType);
    },
    availableRoles() {
      const sigSet = new Set();
      this.templateList.forEach((t) =>
        t["signers_roles"].forEach((r) => sigSet.add(r))
      );

      return this.signerRoles.filter((r) => sigSet.has(r.name));
    },
  },
  watch: {
    selectedFolderType() {
      this.groupList = [];
      this.selectedGroup = null;
      this.templateList = [];
      // this.signerRoles = [];
      this.selectedUsers = {};
    },
  },
  methods: {
    getTemplateGroups(filters) {
      this.loadingGroups = true;
      const type = this.selectedFolderType.id;
      return policiesService
        .fetchTemplateGroups(
          this.companyName,
          this.merchantUniqueName,
          null,
          false,
          this.groupPage,
          this.groupPerPage,
          type,
          filters
        )
        .then((res) => {
          this.groupList = res["groups"];
          this.groupTotal = res["items_found"];
          this.showGroupImport = true;
        })
        .finally(() => (this.loadingGroups = false));
    },
    importGroup(group) {
      console.log("gruppo", group);
      this.selectedGroup = group;
      if (
        group["payment_merchant"] &&
        this.$refs.paymentSelection.getRemoteMerchantValue() == null
      ) {
        // Group has default pay merchant, pass it and disable further selection
        this.$refs.paymentSelection.setRemoteMerchantValue(
          group["payment_merchant"]
        );
        console.log("Disabling dropdown...");
        this.$refs.paymentSelection.setDropdownDisabled(true);
      }
      const templates = group["templates"].map((t) => {
        return {
          ...t,
          signers_name: {},
        };
      });

      // for (let tem of templates) {
      //   const sigSet = new Set();
      //   let metadata = await policiesService.fetchTemplateMetadata(
      //     this.companyName,
      //     tem.id
      //   );
      //   metadata.forEach((m) => {
      //     m["signatureSlots"].forEach((s) => {
      //       sigSet.add(s["signer"]);
      //     });
      //   });

      //   tem["signers"] = sigSet;
      // }

      this.templateList = templates;
      this.timeoutDays = group["timeout_signature_days"];
      this.pecAddr = group["pec_address"] || "";
      this.showPaydayInput = !!templates.find((t) => t["payday_count"] > 0);
      // templates.forEach((t) => {
      //   this.$set(t, "signers_name", {});
      //   // this.$set(this.dataToUpload[t.id], "type", t.type);
      // });

      this.showGroupImport = false;
    },
    async openPreview(template) {
      try {
        this.loadingPreview = true;

        if (template.file) {
          let metadata = await policiesService.fetchTemplateMetadata(
            this.companyName,
            template.id
          );

          const samplePdf = await policiesService.fetchTemplateSamplePdf(
            this.companyName,
            template.id
          );

          const fData = Uint8Array.from(
            atob(samplePdf)
              .split("")
              .map((char) => char.charCodeAt(0))
          );
          const templateFile = new File([fData], template.name, {
            type: "application/pdf",
          });
          const inputFile = template.file;

          const files = [templateFile, inputFile];

          const tab = {
            id: `compare_${template.id}`,
            component: "TemplateCompare",
            label: `Compare ${template.name}`,
            // icon: this.$config.icons.tabs.document_detail, // TODO: add icon
            props: {
              files: files,
              metadata: metadata,
            },
          };

          // const preview = { file: template.file, metadata };

          // const tab = {
          //   id: `pdf_${template.id}`,
          //   component: "Pdf",
          //   label: `Templ. ${template.name}`,
          //   // icon: this.$config.icons.tabs.document_detail, // TODO: add icon
          //   props: {
          //     template: preview,
          //     showTemplateInfo: false,
          //     viewOnly: true,
          //   },
          // };
          this.$store.dispatch("tabs/openTab", tab);
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingPreview = false;
      }
    },
    selectUser(event, template) {
      console.log("template", template);
      const tem = this.templateList.find((t) => t.id === template.id);
      // this.$set(this.dataToUpload[template.type], event.role.id, event.user);

      console.log("tem", tem);
      this.$set(tem["signers_name"], event.role.id, event.user);
    },
    onUserSelect(user, role) {
      this.$set(this.selectedUsers, role.name, user);
    },
    onPaymentSelectionChange(obj) {
      if (obj.paymentRequired !== this.paymentOptions.required) {
        this.$buefy.snackbar.open({
          duration: 5000,
          pauseOnHover: true,
          message: this.$t("policies.upload.payment.remember_choice.text"),
          type: "is-primary",
          actionText: this.$t(
            "policies.upload.payment.remember_choice.confirm"
          ),
          cancelText: this.$t("policies.upload.payment.remember_choice.cancel"),
          onAction: () => {
            localStorage.setItem(
              POLICY_PAYMENT_REQUIRED_STORAGE_KEY,
              obj.paymentRequired
            );
          },
        });
      }
      this.paymentOptions.required = obj.paymentRequired;
      this.paymentOptions.currency = obj.currency;
      this.paymentOptions.amount = obj.amount;
    },
    selectFile(file, template) {
      const tem = this.templateList.find((t) => {
        console.log("t", t);
        console.log("tmplate", template);
        return t.id === template.id;
      });
      this.$set(tem, "file", file);
      // this.$set(this.dataToUpload[template.type], "file", file);
    },
    async upload() {
      let showGenericError = true;
      let relDocId = null;
      try {
        const valid = await this.$refs.observer.validate();
        console.log(valid);
        if (!valid) {
          console.log("Check inputs!");
          return;
        }

        // All signers users must be added
        if (
          this.availableRoles.length !== Object.keys(this.selectedUsers).length
        ) {
          this.$buefy.toast.open({
            message: this.$t("policies.upload.error.select_all_signers"),
            type: "is-danger",
            duration: 5000,
          });
          return;
        }

        this.uploading = true;

        const tempFileRes = {};

        // Check pay merchant selection if payment is required
        const selectedPayMerchantObj = this.$refs.paymentSelection.getRemoteMerchantValue();
        if (this.paymentOptions.required && !selectedPayMerchantObj) {
          // Should not enter here, a payment merchant has not been selected from the dropdown
          this.$buefy.toast.open({
            message: this.$t("policies.upload.error.select_pay_merchant"),
            type: "is-danger",
            duration: 5000,
          });
          console.log("Setting dropdown red border");
          this.$refs.paymentSelection.setDropdownError(true);
          return;
        } else {
          this.$refs.paymentSelection.setDropdownError(false);
        }

        // Upload all the files
        for (let t of this.templateList) {
          let file = t.file;
          const b64 = await getBase64FromFile(file);
          const sha256 = await hexSHA256FromFile(file);
          const mime = file.type;
          let res = await policiesService.uploadPolicyFile(
            this.companyName,
            b64,
            mime,
            sha256
          );
          tempFileRes[t.id] = res;
          // tempFileRes.push({ templateId: t.id, ...res });
        }

        console.log("All files uploaded!");

        const order = ["COMPANY", "AGENCY", "CUSTOMER"];

        let extId = this.extId;
        let additionalExtId = null;
        if (this.isPrivacy) {
          extId = "";
          additionalExtId = "";
        } else if (this.isAppendix) {
          additionalExtId = this.appendixId;
          // extId = extId.concat(`-${this.appendixId}`);
        }

        let paymentDate = null;
        if (this.showPaydayInput && this.paydayDate) {
          paymentDate = toStringISODate(this.paydayDate);
        }

        // Upload metadata
        for (let i = 0; i < order.length; i++) {
          let t = this.templateList.find((t) => t.type === order[i]);
          console.log("t", t);
          if (t) {
            if (relDocId === null) {
              relDocId = tempFileRes[t.id]["document_id"];
            } // TODO: use Promise.all()??
            try {
              console.log("payMerch:", selectedPayMerchantObj);
              await policiesService.uploadPolicyMetadata(
                this.companyName,
                this.className,
                this.merchantUniqueName,
                tempFileRes[t.id]["original_path"],
                tempFileRes[t.id]["document_id"],
                relDocId,
                this.selectedGroup["id"],
                t["id"],
                t["type"],
                t["signers_roles"].includes(KAgent.name)
                  ? this.selectedUsers[KAgent.name]["sub_nickname"]
                  : null,
                t["signers_roles"].includes(KSubAgent.name)
                  ? this.selectedUsers[KSubAgent.name]["sub_nickname"]
                  : null,
                t["signers_roles"].includes(KCustomer.name)
                  ? this.selectedUsers[KCustomer.name]["sub_nickname"]
                  : null,
                this.pecAddr,
                this.timeoutDays,
                extId,
                additionalExtId,
                paymentDate,
                this.paymentOptions.required,
                selectedPayMerchantObj["nickname"] // This should contain the selected pay merchant unique name
              );
            } catch (error) {
              if (error.data && error.data.code === -11101) {
                this.$buefy.toast.open({
                  message: this.$t("policies.upload.error.file_too_short", {
                    name: t["name"],
                  }),
                  type: "is-danger",
                  duration: 4000,
                });
                showGenericError = false;
              }
              throw error;
            }
          }
        }

        await policiesService.startUserNotification(
          this.companyName,
          relDocId,
          this.paymentOptions.amount,
          this.paymentOptions.currency
        );

        // Notify user
        this.$buefy.toast.open({
          message: this.$t("upload.messages.upload_success"),
          type: "is-success",
          duration: 4000,
        });

        this.resetToDefault();
        this.$refs.observer.reset();

        console.log("All metadata uploaded!");
      } catch (error) {
        console.error(error);
        if (relDocId && error.status && error.status >= 500) {
          try {
            await policiesService.deleteUploadedDocuments(
              this.companyName,
              relDocId
            );
          } catch (e) {
            console.log(e);
          }
        }
        if (showGenericError) {
          this.$buefy.toast.open({
            message: this.$t("error.generic"),
            type: "is-danger",
            duration: 4000,
          });
        }
      } finally {
        this.uploading = false;
      }
    },
    resetToDefault() {
      this.pecAddr = null;
      this.extId = "";
      this.appendixId = null;
      this.timeoutDays = null;
      this.showGroupImport = false;
      this.loadingGroups = false;
      // this.loadingMerchants = false;
      this.uploading = false;
      this.folderTypes = folderTypes;
      this.selectedFolderType = folderTypes[0];
      this.groupPage = 1;
      this.groupPerPage = 50;
      this.groupTotal = 0;
      this.groupList = [];
      this.selectedGroup = null;
      this.templateList = [];
      // this.signerRoles = [];
      this.selectedUsers = {};

      this.showPaydayInput = false;
      this.paydayDate = null;
    },
  },
  mounted() {
    // this.loadingMerchants = true;
    // this.fetchMerchants();
    // .then(() => {
    //   this.loadingMerchants = false;
    // });
    policiesService.fetchRoles(this.companyName).then((res) => {
      this.signerRoles = res;
    });

    // Get merchant settings
    policiesService.fetchAllSettings(this.companyName).then((res) => {
      this.isPayEnabled = res[responseSchemas.fetchAllSettings.isPay];

      // Load default payment values from localStorage if found any
      if (this.isPayEnabled) {
        let loaded = localStorage.getItem(POLICY_PAYMENT_REQUIRED_STORAGE_KEY);
        if (loaded) {
          this.paymentOptions.required = JSON.parse(loaded);
        }
      }
    });

    policiesService
      .getRemoteMerchants(this.companyName)
      .then((res) => {
        console.log("remotes", res);
        this.remoteMerchants = res;
      })
      .catch((err) => {
        console.log("remotes_err", err);
        this.remoteMerchants = [];
      });

    this.resetToDefault();
  },
  created() {},
};
</script>

<style></style>
