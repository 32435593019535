<template>
  <b-datepicker
    v-model="innerValue"
    v-bind="$attrs"
    :first-day-of-week="firstDayOfWeek"
    :range="range"
    :position="position"
    :locale="localeUsed"
    ref="picker"
    @focus="calcDropdownAlign"
    :date-formatter="formatDate"
  >
    <template #header>
      <slot name="header"></slot>
    </template>
    <button type="button" class="button is-danger" @click="innerValue = null">
      <b-icon icon="close"></b-icon>
    </button>
  </b-datepicker>
</template>

<script>
import { formatDate } from "../../helpers/date";
export default {
  props: {
    value: {
      type: [Date, String, Array],
      default: null,
    },
    range: {
      type: Boolean,
      default: false,
    },
    firstDayOfWeek: {
      default: 1,
    },
    locale: String,
    dateFormat: {
      default: "yyyy-MM-dd",
    },
  },
  data() {
    return {
      innerValue: null,
      position: "is-bottom-right",
    };
  },
  computed: {
    localeUsed() {
      if (this.locale) return this.locale;
      if (this.$i18n) return this.$i18n.locale;

      return navigator.language;
    },
  },
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit("input", newVal);
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
    },
    range() {
      this.innerValue = null;
      this.$emit("input", this.innerValue);
    },
  },
  methods: {
    calcDropdownAlign() {
      const el = this.$refs.picker.$el;
      const offsetRight =
        document.documentElement.clientWidth - el.getBoundingClientRect().left;

      if (offsetRight < 312) {
        this.position = "is-bottom-left";
      } else {
        this.position = "is-bottom-right";
      }
    },
    formatDate(date) {
      // Date is an array if "range" is enabled
      if (Array.isArray(date)) {
        return date
          .map((d) => formatDate(d, this.dateFormat, this.localeUsed))
          .join(" , ");
      } else {
        return formatDate(date, this.dateFormat, this.localeUsed);
      }
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  },
};
</script>

<style></style>
