<template>
  <b-dropdown
    scrollable
    max-height="150"
    :disabled="isDisabled"
    aria-role="list"
    id="pay-merchant-dropdown"
    v-model="selectedPayMerchant"
    @input="onSelectPayMerchant"
    ref="dropdown"
  >
    <template #trigger>
      <b-button icon-right="menu-down" :class="{ 'has-error': hasError }">
        <span v-if="selectedPayMerchant">{{
          selectedPayMerchant["show_name"]
        }}</span>
        <span v-else>{{
          $t("policies.pay_merchant.empty_dropdown_label")
        }}</span>
      </b-button>
    </template>
    <b-dropdown-item
      v-for="(merchant, index) in payMerchants"
      :key="index"
      :value="merchant"
      aria-role="listitem"
    >
      {{ merchant.show_name }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    payMerchants: {
      type: Array,
      default: () => [],
    },
    selected: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      selectedPayMerchant: this.selected,
      hasError: false,
      isDisabled: this.disabled,
    };
  },
  methods: {
    onSelectPayMerchant() {
      if (this.selectedPayMerchant) this.hasError = false;
      this.$emit("update-selected", this.selectedPayMerchant);
    },
    getValue() {
      return this.selectedPayMerchant;
    },
    setValue(merchantNickname) {
      console.log("Setting PayMerchantDropdown value...");
      this.payMerchants.forEach((merchant) => {
        if (merchant["nickname"] === merchantNickname) {
          console.log("Found PayMerchant!", merchant);
          this.selectedPayMerchant = merchant;
          this.onSelectPayMerchant();
          return;
        }
      });
    },
    setError(isError) {
      this.hasError = isError;
    },
    setDisabled(isDisabled) {
      this.isDisabled = isDisabled;
    },
  },
  created() {},
};
</script>

<style>
.has-error {
  border: 1px red solid;
}
.has-error:hover {
  border: 1px red solid;
}
.has-error:active {
  border: 1px red solid;
}
.has-error:focus {
  border: 1px red solid;
}
</style>
